export const blogList = [
  {
    id: "post1",
    title: "HAVE WE REALLY GROWN UP",
    author: "Sulochana Neupane",
    date: "2021-01-01",
    preview: `It was March 2019. I had a posting at Pediatric Ward. I remember there was a case of a 9-year-old child. He presented with hematuria.
        I was in the 5th semester with little knowledge on history taking, clinical examination, ...`,
    image: "",
  },
  {
    id: "post2",
    title: "MEDICAL STUDENT SYNDROME",
    author: "Shova Sapkota",
    date: "2021-01-01",
    image: "",
    preview: `You are attending a lecture on thyroid disorder and you realize that you have gained some weight in the past few weeks, 
        you are dozing off more frequently in class, you feel cold all the time and you finally come to the conclusion that you may have hypothyroidism. 
        You ...`,
  },
  {
    id: "post3",
    title: "FIRST EXPERIENCE OF TUTORING IN MEDED SERIES",
    author: "Pooja Rokaya",
    date: "2021-01-01",
    image: "",
    preview: `Med Ed, a platform to bring a whole new experience of learning for all the tutors and attendants. It was my first time as a tutor and I was excited as well as nervous at the same time. 
         Thanks to Anu didi, Ajita didi, and Astutee didi who were helping me like angels from ...`,
  },
  {
    id: "post4",
    title: `"I HAVE A QUESTION!"`,
    author: "Manita Khadka",
    date: "2021-01-01",
    image: "",
    preview: `“I have a question!”,
            “Should I raise my hand? This seems pretty easy- How can I not know this? Ugh, this is going to look very silly. I should have read this beforehand; that might have helped me. What is everyone asking? -I cannot even understand their question….. All of them are going to make fun of me. Oh leave it- I will look at it myself later.”,
            Sounds familiar? ...`,
  },
  {
    id: "post5",
    title: `Medical Myths: Hypertension`,
    author: "Astutee Acharya",
    date: "2021-01-01",
    image: "",
    preview: `Blood pressure is the pressure of blood pushing against the walls of your arteries. Blood pressure normally rises and falls throughout the day.
             High blood pressure, also called hypertension, is blood pressure that is higher than normal. A normal blood pressure...`,
  },
  {
    id: "post6",
    title: `Medicine: Away From Home`,
    author: "Ajita Bhatta",
    date: "2021-01-01",
    image: "",
    preview: `As I was getting on the bus, I turned back to see my mom, and both our eyes swelled up and I had to go back for one last embrace. Unlike many students in Nepal who move away from home at a young age to study, I had the privilege to live a sheltered life ...`,
  },
  {
    id: "post7",
    title: `Evolution of a Sphygmomanometer`,
    author: "Anjali Joshi",
    date: "2021-01-01",
    image: "",
    preview: `The sphygmomanometer has had its fair share of collaborations, improvisations and trial and errors to become the most frequently used medical device today. Let us revisit the history that led to the modern-day sphygmomanometer.`,
  },
  {
    id: "post8",
    title: `Vein of Galen Malformation`,
    author: "Prarthana Subedi",
    date: "2021-01-01",
    image: "",
    preview: `A neonate born full term , 37 weeks of gestation , after birth developed tachypnea , tachycardia and oliguria . No past illnesses were documented in the newborn or the mother . Personal and Family history was unremarkable... `,
  },
  {
    id: "post9",
    title: `जयन्तश्री भट्टाचार्यजी को अन्त्य`,
    author: "Bijay KC",
    date: "2021-05-16",
    image: "",
    preview: `शौच गर्न शौचालयको एक कुनामा तम्सिएका मात्र के थिए, ढली पो गए ,फेरी कहिल्यै आफै नउठ्ने गरि, आफ्नो समय सकिन आँटेको उनलाई पूर्वसंकेत पक्कै थिएन, थाहा पाएका भए ढल्ने ठाउँ शौचालय रोज्ने पक्कै थिएनन् , उनी  ढलेको ...`,
  },
  {
    id: "post10",
    title: `Maybe this is it`,
    author: "Akriti Mahat",
    date: "2021-06-03",
    image: "",
    preview: `That smile was so beautiful. I thought to myself. That sparkle in his eyes, the disappearance of the frown, the glee shining in his face. That smile that warmed our hearts. The middle-aged man. He was very welcoming to us. I still ...`,
  },
  {
    id: "post11",
    title: `This year as you wished ... I'll write`,
    author: "Prarthana Subedi",
    date: "2021-06-03",
    image: "",
    preview: `<b>Dear YOU,</b> <br/> So……. no copyright, no credit, as promised. I know you literally could not care if I copy pasted
    your article and gave it my name. I always read the quote <i>“There is no limit to the amount of good you can do if you do not care who gets the credit.”</i>
    `,
  },
  {
    id: "post12",
    title: `Case Report: Does it hold significance?`,
    author: "Saurabh Tiwari",
    date: "2021-06-15",
    image: "",
    preview: `Agitated  and taking a deep breath, I went to the associate professor who took our lecture a couple of hours ago. She is an ideal teacher praised by many other pupils. She amazes everyone with her witty dialect. I was eager to do something ...`,
  },
  {
    id: "post13",
    title: `What makes you different?`,
    author: "Prajwal Bhandari",
    date: "2021-06-15",
    image: "",
    preview: `Have you ever wondered what makes successful people different from others? You might think if it must be because of the amount of hard work they are consistently doing in their field or their attitude towards their goals...`,
  },
];

import React from 'react';
import './contactPage.styles.scss';
import Contact from '../../components/Contact/contact.component';

const ContactPage = () => {
  return (
    <div className='contact-page'>
      <div className='title'>GET IN TOUCH</div>
      <Contact />
    </div>
  );
};

export default ContactPage;

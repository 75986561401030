import React from 'react';
import './contact.styles.scss';
import Form from "../Form/form.component";
import { contactText } from '../../text/contact/contact';
import locationImage from '../../images/icons/home.png';
import emailImage from '../../images/icons/email.png';

const Contact = (props) => {
    const formOptions = { header: 'Contact Us', url: '' };
    return (
        <div className='contact-container'>
              <div className="contact-form">
                  <Form formOptions={formOptions} />
                  <div className="contact-info">
                      <div className={'location'}>
                          <span><img src={locationImage} alt={'location'}/></span>
                          <span>Kathmandu, Nepal</span>
                      </div>
                      <div className={'email'}>
                          <span><img src={emailImage} alt={'email'}/></span>
                          <span>doctormp0110@gmail.com</span>
                      </div>
                  </div>
              </div>
              <div className="faq-container">
                  {
                      contactText.map((value, index) => {
                          return (
                              <div className={'q-and-a'} key={index}>
                                  <p className={'question'} style={{fontWeight: 'bold'}}>{value['question']}</p>
                                  <p className={'answer'}>{value['answer']}</p>
                              </div>
                          )
                      })
                  }
              </div>
        </div>
  );
};

export default Contact;

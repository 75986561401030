import React from 'react';
import './card.styles.scss';

const Card = ({
  image,
  alt,
  imageStyles,
  text
}) => {
    const { display, width, height, objectFit } = imageStyles;
    return (
        <div className='card-container'>
            <div className='image-container'>
                <img
                    src={image}
                    alt={alt}
                    style={{
                        display: display,
                        width: width,
                        height: height,
                        objectFit: objectFit,
                    }}
                />
            </div>
            <div className='text-container'>
                <pre>{text}</pre>
            </div>
        </div>
    );
}

export default Card;
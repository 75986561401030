import React from 'react';
import './footer.styles.scss';

const Footer = (props) => {
  return (
    <div className='footer'>
      <div className='copyright'>&copy;MedLife Stories 2020.</div>
      <div className='spacer'></div>
      <div className='logo-container'>
        <div className='media-links'>
          <a
            href='https://www.facebook.com/MedLifeStories'
            target='_blank'
            rel='noopener noreferrer'
          >
            Facebook
          </a>
        </div>
        <div className='media-links'>
          <a
            href='https://twitter.com/MedlifeStories'
            target='_blank'
            rel='noopener noreferrer'
          >
            Twitter
          </a>
        </div>
        <div className='media-links'>
          <a
            href=' https://www.instagram.com/medlife_stories07/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Instagram
          </a>
        </div>
        <div className='media-links'>
          <a
            href='https://www.linkedin.com/in/medlife-stories-54b9591ba/'
            target='_blank'
            rel='noopener noreferrer'
          >
            LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

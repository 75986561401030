import React from 'react';
import './cardlayout.styles.scss';
import Card from "../Card/card.component";

const CardLayout = ({ data }) => {
    return (
        <div className='card-layout'>
            {data.map((item, index) => {
                return <Card
                    key={index}
                    image={item.image}
                    text={item.text}
                    alt={item.alt}
                    imageStyles={item.imageStyles}
                />
            })}
        </div>
    );
}

export default CardLayout;
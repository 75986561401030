import React, { useState } from 'react';
import './navigation.styles.scss';
import Navbar from './Navbar/navbar.component';
import SideBar from './SideBar/sidebar.component.jsx';
import BackDrop from './Backdrop/backdrop.component.jsx';

const Navigation = ({items, styles, sideBarItems, logo} ) => {
  const [sideBarOpen, setSidebarStatus] = useState(false);
  const sideBarHandler = () => {
    setSidebarStatus((prevState) => {
      return { sideBarOpen: !prevState.sideBarOpen };
    });
  };
  const backdropHandler = () => {
    setSidebarStatus(false);
  };
  let backDrop;
  if (sideBarOpen) {
    backDrop = <BackDrop backdropHandler={backdropHandler} />;
  }
  return (
    <div className='navigation'>
      <Navbar items={items} styles={styles} sideBarHandler={sideBarHandler} logo = {logo}/>
      <SideBar visible={sideBarOpen} items={sideBarItems} styles={styles} backdropHandler={backdropHandler}/>
      {backDrop}
    </div>
  );
};

export default Navigation;

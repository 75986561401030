import React from 'react';
import './projectsPage.styles.scss';
import Project from '../../components/Project/project.component';
import {projectList} from '../../text/project/projectList';

const ProjectsPage = () => (
  <div className='projects-page'>
    <div className='title'>PROJECTS</div>
    <Project projectList={projectList}/>
  </div>
);

export default ProjectsPage;

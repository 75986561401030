import React, {useState} from 'react';
import './project.styles.scss';
import {Link} from "react-router-dom";

const Project = ({projectList}) => {
  const [active , toggleProject] = useState('1');
  const changeProject = (event) => {
    let selectedClass = event.target.className;
    selectedClass = selectedClass.replace('project-button-', '');
    if(selectedClass !== active){
      toggleProject(selectedClass);
    }
  };
  let currentColor = 'red';
  return (
    <div className='project-container'>
      <div className="buttonsContainer">
        {
          projectList.map((value, index) => {
            currentColor = (parseInt(active) === index+1) ? '#f8f8f8' : '#e5e5e5'; 
            return (
              <div className = 'project-button' key = {index} style={{backgroundColor: currentColor}}>
                <div className = {`project-button-${index+1}`} onClick={changeProject}>{value['title']}</div>
              </div>
            )
          })
        }
      </div>
      <div className="project-content">
        {
          projectList.map((project, index) => {
            return (active === project['id']) ? (
                <div id = {`project-${index + 1}`} key = {index}>
                    <div id = {`project-text`}  dangerouslySetInnerHTML={{__html: project['description']}}/>
                    <div className={'space'}/>
                    <div className={'link-button-container'}>
                        {
                            (project['buttonText'].length > 1) ?
                                project['buttonText'].map((buttonText, index) => {
                                    return (
                                        <Link to={`${project['buttonLinks'][index]}`} key={`button${project['id']}${index}`}>
                                            <div id={'project-link-button'}>
                                                <span>{buttonText}</span>
                                            </div>
                                        </Link>
                                    )
                                }) : (project['buttonText'].length === 1) ?
                                <Link to={`${project['buttonLinks'][0]}`} key={`button${project['id']}${index}`}>
                                    <div id={'project-link-button'}><span>{project['buttonText']}</span></div>
                                </Link>
                                : ''
                        }
                    </div>
                </div>
            ) : ''
          })
        }
      </div>
    </div>
  )
};

export default Project;

import React from 'react';
import DrawerButton from './Drawerbutton/drawerbutton.component';
import './navbar.styles.scss';
import { Link } from 'react-router-dom';
import Logo from '../../../images/logo.PNG';

/**
 *
 * @param {*}
 * items => items to display in the main Navbar
 * styles => styling options
 * sideBarHandler => onClikcHandler to show/hide sideBar
 */
const Navbar = ({ items, styles, sideBarHandler, logo }) => {
  const createSlug = (item) => {
    item = item.toLowerCase();
    item = item.replace(' ', '-');
    return item;
  };

  return (
    <div
      className='navbar-container'
      style={{color: styles.color }}
    >
      <nav className='navbar-navigation'>
        <div className='logo'>
          <Link to='/'>
            <div className = 'logo-back'/>
          </Link>
          <Link className='logo-text-link' to='/'>
            <div className="logo-text">Medlife Stories</div>
          </Link>
        </div>
        <div className='spacer' />
        <div className='menu-links'>
          {items.map((item, index) => (
            <span className='menu-item' key={index}>
              <Link
                className={`${createSlug(item)}-link`}
                to={`/${createSlug(item)}`}
                style={{
                  color: styles.color,
                  fontSize: styles.fontSize,
                }}
              >
                {item}
              </Link>
            </span>
          ))}
        </div>
        <div className='hamburger'>
          <DrawerButton sideBarHandler={sideBarHandler} />
        </div>
      </nav>
    </div>
  );
};
export default Navbar;

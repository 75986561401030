import React from 'react';
import './blog.styles.scss';
import BlogLayout from '../BlogLayout/bloglayout.component';

const Blog = ({ blogList, adminRoute }) => {
  return (
    <div className='blog-container'>
      <BlogLayout className='blog-layout-component' blogList={blogList} adminRoute={adminRoute} />
    </div>
  );
};

export default Blog;

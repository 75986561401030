import React, { useState, useEffect } from 'react';
import './blogPage.styles.scss';
import Blog from '../../components/Blog/blog.component';
import BlogPost from '../../components/BlogPost/blogpost.component';
import { blogContent } from '../../text/blog/blogContent';
import { blogList } from '../../text/blog/blog';
import { Route, Switch } from 'react-router-dom';

const BlogPage = () => {
  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(255,255,255,1)';
    return () => {
      document.body.style.backgroundColor = '#e5e5e5';
    };
  }, []);
  let [filteredList, filterList] = useState(blogList);
  const [title, handleTitle] = useState('');
  const [from, handleFrom] = useState('');
  const [to, handleTo] = useState('');
  const [author, handleAuthor] = useState('');
  const filterPosts = () => {
    let list = blogList.filter((item, index) => {
      if (
        !item.author.toLowerCase().includes(author.toLowerCase()) &&
        author !== ''
      ) {
        return false;
      }
      if (
        !item.title.toLowerCase().includes(title.toLowerCase()) &&
        title !== ''
      ) {
        return false;
      }
      if (!(Date.parse(item.date) >= Date.parse(from)) && from !== '') {
        return false;
      }
      if (!(Date.parse(item.date) <= Date.parse(to)) && to !== '') {
        return false;
      }
      return true;
    });
    filterList(list);
  };
  return (
    <div className="blog-page">
      <Switch>
        <Route exact path="/blog">
          <div className="title">MEDLIFE BLOG</div>
          <p className="submission">
            To include your articles, please submit your submissions to{' '}
            <span>mlstoriesblog@gmail.com</span>. Please take a look at the
            submission guidelines at the bottom of the page.
          </p>
          <div className="filter-bar">
            <div className="filter-title">
              <label htmlFor="title">TITLE : </label>
              <input
                type="text"
                name="title"
                onInput={(e) => handleTitle(e.target.value)}
              />
            </div>
            <div className="filter-date-from">
              <label htmlFor="date-from">FROM : </label>
              <input
                type="date"
                name="date-from"
                onInput={(e) => handleFrom(e.target.value)}
              />
            </div>
            <div className="filter-date-to">
              <label htmlFor="date-to">TO : </label>
              <input
                type="date"
                name="date-to"
                onInput={(e) => handleTo(e.target.value)}
              />
            </div>
            <div className="filter-author">
              <label htmlFor="author">Author : </label>
              <input
                type="text"
                name="author"
                onInput={(e) => handleAuthor(e.target.value)}
              />
            </div>
            <div className="filter-button" onClick={filterPosts}>
              Search
            </div>
          </div>
          <Blog blogList={filteredList} />
          <div className="guidelines">
            <span>Submission Guidelines</span>
            <p className="guidelines-content">
              We invite medical students and doctors to send us your articles
              for publication in the Medlife Blog. Please consider reading the
              submission guidelines before you send us your article.
            </p>
            <div className="guidelines-content">
              <ul>
                <li>
                  The article should be well written in standard Nepali or
                  English language.
                </li>
                <li>
                  It should not have been published elsewhere nor should be
                  under consideration for publication at any other platform.
                </li>
                <li>
                  It should be sent in Word file format or shared as Google doc.
                </li>
                <li>
                  Including figures, tables and pictures if necessary is highly
                  recommended.
                </li>
                <li>Proper reference should be given wherever applicable.</li>
                <li>
                  The articles should fall within one of our article categories.
                </li>
              </ul>
            </div>
            <p className="guidelines-content">
              Upon submission, our editorial team will review the articles. If
              required, we will send you your article with comments from our
              editorial team to make changes.
            </p>
            <div className="guidelines-content">
              Article types: We accept articles under the following categories.
              <ul>
                <li>
                  <span>Education:</span>
                  <p>
                    Education articles should aim at communicating scientific
                    information to the medical community. The articles can be
                    presented in plain facts or as case based discussion. For
                    image based description, patient consent is necessary.
                  </p>
                </li>
                <li>
                  <span>Perspectives:</span>
                  <p>
                    {' '}
                    Perspective articles are those that help you frame your
                    opinion into a valid, reasonable and arguable piece. Your
                    perspectives about medical education, life of medical
                    students, health care system, medical practice and ethics
                    could make into this category.
                  </p>
                </li>
                <li>
                  <span>Personal experience:</span>
                  <p>
                    {' '}
                    Every personal experience we go through enlightens us in
                    ways that help us grow even better. Share your own
                    experiences here and reach out to everyone with your life
                    lesson.
                  </p>
                </li>
                <li>
                  <span>Interviews: </span>
                  <p>
                    {' '}
                    Interview articles are interviews with someone from medical
                    fraternity, patient or those working in health care policy.
                    The articles should quote the people involved and should
                    highlight the importance of the interview.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Route>
        {filteredList.map((value, index) => {
          let contentPath = `/blog/${value['id']}`;
          return (
            <Route key={index} exact path={contentPath}>
              <BlogPost blogPost={blogContent[index]} />
            </Route>
          );
        })}
      </Switch>
      <div className="spacer" />
    </div>
  );
};

export default BlogPage;

const formId = '1FAIpQLScZd20FJpb9KLyveemohZD-YvnKf5noagV5LwrOQbhfFF3bCQ';
const name = 'entry.1588714152';
const medicalCollege = 'entry.1838614503';
const year = 'entry.965373244';
const email = 'entry.696661459';
const residence = 'entry.112334224';
const social = 'entry.122044346';
const how = 'entry.1824529738';
const getPath = (formId) =>
  `https://docs.google.com/forms/d/e/${formId}/formResponse`;

const postToGoogleDB = function (data) {
  const path = getPath(formId);
  const url = getURL(path, data);
  sendRequest('POST', url).then(responseEvent);
};
const getURL = function (path, params) {
  const url = new URL(path);
  for (let key in params) {
    url.searchParams.set(key, params[key]);
  }
  return url;
};
const sendRequest = async function (verb, url) {
  const request = initRequest(verb, url);
  const response = await fetch(request);
  return response;
};
const initRequest = function (verb, url) {
  const init = new Object();
  init.method = verb;
  init.mode = 'no-cors';
  return new Request(url, init);
};
const responseEvent = (response) => {
  alert('Success!');
};
export const submitEvent = function (
  inputName,
  inputMedical,
  inputYear,
  inputEmail,
  inputResidence,
  inputSocial,
  inputHow
) {
  const formData = new Object();
  formData[name] = inputName;
  formData[medicalCollege] = inputMedical;
  formData[year] = inputYear;
  formData[email] = inputEmail;
  formData[residence] = inputResidence;
  formData[social] = inputSocial;
  formData[how] = inputHow;
  postToGoogleDB(formData);
};

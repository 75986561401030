import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BlogPost from '../BlogPost/blogpost.component';
import './blogeditor.styles.scss';
import { EditorState, RichUtils, convertFromRaw, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const BlogEditor = () => {
    let history = useHistory();
    const goBack = () => history.goBack();
    //edited state data
    const [formTitle, setFormTitle] = useState('Title');
    const [formName, setFormName] = useState('Author');
    const [formTag, setFormTag] = useState('education');
    const [formContent, setFormContent] = useState('');
    const [formDate, setFormDate] = useState('mm/dd/yyyy');
    const initialContent = {
        title: '',
        author: '',
        date: '',
        image: '',
        tag: '',
        content: [],
        images: [],
        captions: []
    };
    const [editedContent, setEdit] = useState(initialContent);
    useEffect(() => {
        let content = {
            title: formTitle,
            author: formName,
            date: formDate,
            image: '',
            tag: formTag,
            content: [formContent],
            images: [],
            captions: [],
            preview: true
        }
        setEdit(content)

    }, [formTitle, formName, formDate, formTag, formContent]);

    //draft-js
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const  [convertedContent, setConvertedContent] = useState(null);
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }
    const convertContentToHTML = () => {
        let currentContentAsRaw = convertToRaw(editorState.getCurrentContent());
        let currentContentAsHTML = draftToHtml(
            currentContentAsRaw
        );
        let purifiedHTML = createMarkup(currentContentAsHTML);
        setConvertedContent(purifiedHTML);
        setFormContent(purifiedHTML);
    }
    const createMarkup = (html) => {
        return  DOMPurify.sanitize(html);
    }

    return (
        <div className='post-container'>
            <div className='blog-editor' action="" method="">
                <input type="hidden" name="articleId" value="7" />
                <ul className="unordered-list">
                    <li className="list-options">
                        <label htmlFor="title">Article Title</label>
                    </li>
                    <li className="list-options">
                        <input type="text" name="title" id="title" placeholder="Name of the article" required="" onChange={(e) => setFormTitle(e.target.value)} />
                    </li>
                    <li className="list-options">
                        <label htmlFor="author">Author Name</label>
                    </li>
                    <li className="list-options">
                        <input type="text" name="author" placeholder="Name of the author" required="" onChange={(e) => setFormName(e.target.value)} />
                    </li>
                    <li className="list-options">
                        <label htmlFor="tag">Blog Category Tag</label>
                    </li>
                    <li className="list-options">
                        <select id="tag" name="tag" required="" onChange={(e) => setFormTag(e.target.value)}>
                            <option value="education">Education</option>
                            <option value="personal experience">Personal Experience</option>
                            <option value="perspective">Perspective</option>
                            <option value="interview">Interview</option>
                        </select>
                    </li>
                    <li className="list-options">
                        <label htmlFor="content">Article Content</label>
                    </li>
                    <div className='editor'>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            // wrapperClassName="wrapper-class"
                            // editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        />
                    </div>
                    <li className="list-options">
                        <label htmlFor="publicationDate">Publication Date</label>
                    </li>
                    <li className="list-options">
                        <input type="date" name="publicationDate" id="publicationDate" placeholder="YYYY-MM-DD" required="" onChange={(e) => setFormDate(e.target.value)} />
                    </li>

                </ul>

                <div className="buttons">
                    <div className="save">Save</div>
                    <div className="cancel" onClick={goBack}>Cancel</div>
                </div>
                <div className='preview'>
                    <h1>Preview</h1>
                    <div className='post'>
                        <BlogPost blogPost={editedContent} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogEditor;

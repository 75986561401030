export const contactText = [
  {
    question: `How can I be a part of MedLife Community?`,
    answer: `The Medlife community is a safe community where you learn with your peers . “ Cognitive - Congruence hypothesis states that , we learn better with people of our cognitive congruence”. To be a part , please fill the form given in the MedEd series.`
  },
  {
    question: `What can I contribute?`,
    answer: `There are tiers to MedLife . Teaching and learning are the two best combinations of the world .  You can become a medlife tutor, a college representative , or get more involved by coming over to the core team. Applications will be on a rolling basis . See projects tab for more info. `
  },
  {
    question: `What can I learn? `,
    answer: `There is so much to learn with MedLife. It can be the first journey of you towards becoming a professor . If you are a Medical Educator or learn more by teaching MedLife is for you . We welcome you to the team .`
  },
  {
    question: `What is MedLife ?`,
    answer: `Medlife stories is a competency based organization , which upholds the value of students for students. It is a collaborative approach to bring medical students all over Nepal to ensure quality medical education.`
  },
  {
    question: `What is the process of taking part in the MedEd Series ? `,
    answer: `In The MedEd series you can become a tutor by filling the form  . Given below . After that there are a series of processes but we have you sorted .  You just need to concentrate on the session , we will be with you along every step. `
  },
  {
    question: `What can I do to get involved ? `,
    answer: `To be a part of the community join us on Facebook. and other social media (see website footer), fill the 'Contact Us' form.`
  }
];

import React from 'react';
import './about.styles.scss';
import ImageAndText from '../ImageAndText/imageandtext.component';
import { aboutText } from '../../text/about/about';

const About = (props) => {
  const imageStyles = {
    width: '100%',
    display: 'inline-block',
    height: '100%',
    objectFit: 'cover',
  };
  const textStyles = {
    fontSize: 'calc(10px + 0.5vw)',
    fontFamily: 'Cambria',
  };
  const headerStyles = {
    headerSize: '20px',
    fontFamily: 'Arial',
  };
  // imported text to display from Text folder
  const { para1, para2, para3 } = aboutText;
  return (
    <div className='about-container'>
      <ImageAndText
        image={''}
        alt={''}
        header={''}
        text={para1}
        headerStyles={headerStyles}
        imageStyles={imageStyles}
        textStyles={textStyles}
        imageRight={false}
        imageHide={true}
      />
      <ImageAndText
        image={''}
        alt={''}
        header={''}
        text={para2}
        headerStyles={headerStyles}
        imageStyles={imageStyles}
        textStyles={textStyles}
        imageRight={false}
        imageHide={true}
      />
      <ImageAndText
          image={''}
          alt={''}
          header={''}
          text={para3}
          headerStyles={headerStyles}
          imageStyles={imageStyles}
          textStyles={textStyles}
          imageRight={false}
          imageHide={true}
      />
    </div>
  );
};

export default About;

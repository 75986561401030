import React from 'react';
import './drawerbutton.styles.scss';
const DrawerButton = (props) => {
  return (
    <button className='toggle-button' onClick={props.sideBarHandler}>
      <div className='bar-line' />
      <div className='bar-line' />
      <div className='bar-line' />
    </button>
  );
};

export default DrawerButton;

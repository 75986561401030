import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import EventsPage from './pages/eventsPage/eventsPage.component';
import ContactPage from './pages/contactPage/contactPage.component';
import AboutPage from './pages/aboutPage/aboutPage.component';
import JournalPage from './pages/journalPage/journalPage.component';
import BlogPage from './pages/blogPage/blogPage.component';
import ProjectsPage from './pages/projectsPage/projectsPage.component';
import Navigation from './components/Navigation/navigation.component';
import Homepage from './pages/homepage/homepage.component';
import AdminBlogPage from './pages/adminBlogPage/adminBlogPage.component';
import Footer from './components/Footer/footer.component';

/**
 * Styles supported => {color, backgroundColor, fontSize, fontFamily}
 * for {sideBar => {sideBarColor, sideBarBackgroundColor, sideBarFontSize, sideBarFontFamily}}
 */
function App() {
  const items = [
    'ABOUT',
    'PROJECTS',
    'EVENTS',
    'JOURNAL',
    'BLOG',
    'CONTACT',
    'JOIN US',
  ];
  const sideBarItems = [
    'ABOUT',
    'PROJECTS',
    'EVENTS',
    'JOURNAL',
    'BLOG',
    'CONTACT',
    'JOIN US',
  ];
  //Navbar and sidebar styles
  const styles = {
    color: '#fff',
    fontSize: 20,
    fontFamily: 'PoppinsRegular',
    sideBarColor: '#E5E5E5',
    sideBarFontSize: 28,
    sideBarFontFamily: 'PoppinsRegular',
  };
  return (
    <div className="App">
      <Navigation
        items={items}
        styles={styles}
        sideBarItems={sideBarItems}
        logo={'Logo'}
      />
      <Switch>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="/blog">
          <BlogPage />
        </Route>
        <Route path="/events">
          <EventsPage />
        </Route>
        <Route path="/journal">
          <JournalPage />
        </Route>
        <Route path="/projects">
          <ProjectsPage />
        </Route>
        <Route path="/contact">
          <ContactPage />
        </Route>
        <Route path="/join-us">
          <ContactPage />
        </Route>
        <Route path="/blog-edit">
          <AdminBlogPage />
        </Route>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/medLife">
          <Homepage />
        </Route>
      </Switch>
      <div className="spacer" />
      <Footer />
    </div>
  );
}

export default App;

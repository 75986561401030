import React, { useState } from "react";
import "./home.styles.scss";
import { HashLink as Link } from "react-router-hash-link";

const Home = (props) => {
  const links = ["#slide-1", "#slide-2", "#slide-3"];
  const [active, toggleClass] = useState("#slide-1");
  const changeColor = (event) => {
    let selectedClass = event.target.className;
    if (selectedClass !== "current") {
      toggleClass(selectedClass);
    }
  };
  return (
    <div className="home-container">
      <div className="cover">
        <div className="slider">
          <div className="slides">
            <div id="slide-1">
              <p className="cover-text">We Celebrate the Process of Learning</p>
            </div>
            <div id="slide-2">
              <p className="cover-text">Creating Healthcare Leaders</p>
            </div>
            <div id="slide-3">
              <p className="cover-text">Encouraging Student Participation</p>
            </div>
          </div>
          <div className="page-circles">
            {links.map((value, index) => (
              <Link
                className={active === value ? "current" : value}
                key={index}
                to={value}
                onClick={changeColor}
              >
                next page
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

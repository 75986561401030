import React, { useEffect, useState } from 'react';
import './adminBlogPage.styles.scss';
import Blog from '../../components/Blog/blog.component';
import BlogPostEditor from "../../components/BlogPostEditor/blogposteditor.component";
import BlogEditor from "../../components/BlogEditor/blogeditor.component";
import { blogContent } from '../../text/blog/blogContent';
import { blogList } from '../../text/blog/blog';
import { Route, Switch, useRouteMatch } from "react-router-dom";

const AdminBlogPage = () => {
    const { path, url } = useRouteMatch();
    //filter data
    const [filteredList, filterList] = useState(blogList);
    const [id, handleID] = useState('');
    const [title, handleTitle] = useState('');
    const [from, handleFrom] = useState('');
    const [to, handleTo] = useState('');
    const [author, handleAuthor] = useState('');
    const filterPosts = () => {
        let list = blogList.filter((item, index) => {
            if (item.id !== `post${parseInt(id)}` && id !== '') {
                return false;
            }
            if (!item.author.toLowerCase().includes(author.toLowerCase()) && author !== '') {
                return false;
            }
            if (!item.title.toLowerCase().includes(title.toLowerCase()) && title !== '') {
                return false;
            }
            if (!(Date.parse(item.date) >= Date.parse(from)) && from !== '') {
                return false;
            }
            if (!(Date.parse(item.date) <= Date.parse(to)) && to !== '') {
                return false;
            }
            return true;
        });
        filterList(list);
    }

    //blogeditor Data


    return (
        <div className='blog-page'>
            <Switch>
                {filteredList.map((value, index) => {
                    let contentPath = `${path}/${value['id']}`;
                    return (
                        <Route key={index} path={contentPath} >
                            <BlogEditor blogPost={blogContent[index]} />
                        </Route>
                    );
                })}
                <Route path='/blog-edit'>
                    <div className='title'>BLOGS PAGE</div>
                    <div className='filter-bar'>
                        <div className="filter-id">
                            <label htmlFor='id'>ID : </label>
                            <input type='text' name='id' onInput={e => handleID(e.target.value)} />
                        </div>
                        <div className="filter-title">
                            <label htmlFor='title'>TITLE : </label>
                            <input type='text' name='title' onInput={e => handleTitle(e.target.value)} />
                        </div>
                        <div className="filter-date-from">
                            <label htmlFor='date-from'>FROM : </label>
                            <input type='date' name='date-from' onInput={e => handleFrom(e.target.value)} />
                        </div>
                        <div className="filter-date-to">
                            <label htmlFor='date-to'>TO : </label>
                            <input type='date' name='date-to' onInput={e => handleTo(e.target.value)} />
                        </div>
                        <div className="filter-author">
                            <label htmlFor='author'>Author : </label>
                            <input type='text' name='author' onInput={e => handleAuthor((e.target.value))} />
                        </div>
                        <div className="filter-button" onClick={filterPosts}>
                            Search
                    </div>
                    </div>
                    <Blog blogList={filteredList} adminRoute={true} />
                </Route>
            </Switch>
            <div className='spacer' />
        </div >
    );
};

export default AdminBlogPage;

import React from 'react';
import './journalPage.styles.scss';
import CardLayout from '../../components/CardLayout/cardlayout.component';
import Mem1 from '../../images/journalPics/sabina.jpg';
import Mem2 from '../../images/journalPics/soumya.jpg';
import Mem3 from '../../images/journalPics/bipana.jpg';
import Mem4 from '../../images/journalPics/abhinash.jpg';
import Mem5 from '../../images/journalPics/prarthana.jpg';

const imageStyles = {
  width: '150px',
  height: '150px',
  display: 'inline-block',
  objectFit: 'cover',
};
const journalData = [
  {
    image: Mem1,
    text: `Sabina Khadka\nMBBS, NAIHS`,
    alt: 'Team',
    imageStyles: imageStyles,
  },
  {
    image: Mem2,
    text: `Soumya Pahari\nMBBS, NAIHS`,
    alt: 'Team',
    imageStyles: imageStyles,
  },
  {
    image: Mem3,
    text: `Bipana Gurung\nMBBS, CMC`,
    alt: 'Team',
    imageStyles: imageStyles,
  },
  {
    image: Mem4,
    text: `Abhinash Baniya\nMBBS, CMC`,
    alt: 'Team',
    imageStyles: imageStyles,
  },
  {
    image: Mem5,
    text: `Prarthana Subedi\nMBBS, NAIHS`,
    alt: 'Team',
    imageStyles: imageStyles,
  },
];

const JournalPage = () => {
  const formLink = `https://docs.google.com/forms/d/e/1FAIpQLScytI7m4vnh66_9PN_YVtVOXwlZA_nfj29WmcAt4dJcmfBVCQ/viewform?fbclid=IwAR0TTt8qi7QLMMYMala-6E0P4YfX8KARjgn7QV_4-GqB_trF0kaTu6RpWyM`;
  return (
    <div className="journal-page">
      <div className="title">MEDLIFE JOURNAL</div>
      <div className="description">
        We are pleased to announce the membership call for ‘Medlife Journal
        Club’ with the aim of connecting enthusiastic medical and paramedical
        students from all over the country, who are interested in promoting
        scientific research and its impact on healthcare practice. We would like
        to invite you all to this platform to regularly discuss various
        scientific papers and findings, so that we can boost our skills
        together, in reading articles, their critical appraisal and gain
        concerned scientific knowledge, whilst also highlighting new findings,
        sharing resources, and providing peer support to each other.
        <br />
        <br />
        <div className="empty"></div>
        <a href={formLink} target="_blank" rel="noopener noreferrer">
          <div className="button">REGISTER</div>
        </a>
      </div>
      <div className="team">Journal Team</div>
      <CardLayout data={journalData} />
    </div>
  );
};

export default JournalPage;

import React from 'react';
import './blogpost.styles.scss';
const BlogPost = ({ blogPost, title }) => {
    const captions = blogPost['captions'];
    const preview = blogPost['preview'];
    return (
        <div className='post-container'>
            <div className="intro">
                <div className='post-title'>{blogPost['title']}</div>
                <div className='info'>
                    <div className="writer-info">
                        <span className='author-tag'>By {blogPost['author']},</span>
                        <span className='date-tag'>{blogPost['date']}</span>
                    </div>
                    <div className="info-tag">{blogPost['tag']}</div>
                </div>
            </div>
            {/*blog cover image*/}
            <div className="image-container" style={{ backgroundImage: `url(${blogPost['image'].default})` }} />
            {
                (preview) ?
                    <div className = 'preview' dangerouslySetInnerHTML = {{__html: blogPost['content'][0]}}/>
                    :
                    <div className="post-body">
                        {
                            blogPost['content'].map((value, index) => (
                                <p className='body-para' key={index}
                                   dangerouslySetInnerHTML={{__html: value}}>
                                </p>
                            ))
                        }
                    </div>
            }
            <div className='post-gallery'>
                {
                    (blogPost['images'].length > 0) ?
                        blogPost['images'].map((value, index) => {
                            return (
                                <div className={'gallery-img-container'} key={index}>
                                    <img className={'blog-gallery-images'} src={value.default} alt='gallery' />
                                    {(captions.length > 0) ? <span>{blogPost['captions'][index]}</span> : ""}
                                </div>
                            )
                        }
                        ) : ''
                }
            </div>
        </div>
    );
};

export default BlogPost;

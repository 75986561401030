import React from 'react';
import './bloglayout.styles.scss';
import { Link } from 'react-router-dom';

const BlogLayout = ({ blogList, adminRoute }) => {
  let reversedList = [...blogList].reverse();
  let page = adminRoute ? 'blog-edit' : 'blog';
  return (
    <div className="blog-layout">
      {reversedList.map((value, index) => {
        return (
          <Link to={`/${page}/${reversedList[index]['id']}`} key={index}>
            <div className="blog-post">
              <div className="upper">
                <p className="blog-title">{reversedList[index]['title']}</p>
              </div>
              <div className="lower">
                <p className="blog-author">
                  {reversedList[index]['author']} {reversedList[index]['date']}
                </p>
                <p
                  className="blog-preview"
                  dangerouslySetInnerHTML={{
                    __html: reversedList[index]['preview'],
                  }}
                />
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default BlogLayout;

import React from 'react';
import './eventsPage.styles.scss';
import Event from '../../components/Event/event.component';
import event2 from '../../images/events/trivia-night.png';
import event1 from '../../images/events/medx.jpg';
import { Switch, Route } from 'react-router-dom';

const EventsPage = () => {
  const data1 = {
    eventName: 'MedDx Series: Sharpen Your Clinical Acumen',
    location: 'Zoom by invitation',
    date: 'Jan 02, 2021. 3:00 P.M. Nepali Time',
    description: 'A brand new series that teaches us how to put our understanding into action. Sessions will attempt to emulate real life clinical scenarios to interrelate the concepts we know. Please fill out the contact form for more information.',
    image: event1,
    link: '/contact'
  };
  const data2 = {
    eventName: 'Trivia Night: Online Medical Quiz',
    location: 'Zoom by invitation',
    date: 'December 28, 2020. 9:00 P.M. Nepali Time',
    description:
      'Join us for a night of fun learning with prizes up for grabs.',
    image: event2,
    link: '/events'
  };
  const data3 = {
    eventName: 'New Event to Add',
    location: 'Zoom by invitation',
    date: 'Jan 02, 2021. 3:00 P.M. Nepali Time',
    description: 'We will be adding new event here',
    image: event1,
    link: '/contact'
  };
  const imageStyles = {
    width: '100%',
    display: 'inline-block',
    height: '100%',
    objectFit: 'contain',
  };
  const textStyles = {
    fontSize: 'calc(10px + 0.5vw)',
    fontFamily: 'Cambria',
  };
  return (
    <div className='events-page'>
      <div className='title'>BE PART OF OUR EVENTS</div>
      <Event data={data1} />
      <Event data={data2} />
    </div>
  );
};

export default EventsPage;

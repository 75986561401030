import React from 'react';
import './event.styles.scss';
import calendar from '../../images/icons/calendar.png';
import venue from '../../images/icons/location.png';
import {Link} from "react-router-dom";

const Event = ({ data }) => {
  const { eventName, location, date, description, image, link } = data;
  return (
    <div className='event-container'>
        <div className='left'>
            <div className='image-container'>
                <img className='event-image' src={image} alt='event'/>
            </div>
        </div>
        <div className='right'>
            <div className='event-name'>{eventName}</div>
            <div className='location'><img className='icon' src={venue} alt={'location'}/>{location}</div>
            <div className='date'><img className='icon' src={calendar} alt={'date'}/>{date}</div>
            <div className='description'>{description}</div>
            <div className='register-button'>
                <Link to = {link}>
                    Register
                </Link>
            </div>
        </div>
    </div>
  );
};
export default Event;

import React from 'react';
import './sidebar.styles.scss';
import {Link} from 'react-router-dom';
/**
 * items => items to display
 * styles => styles to apply 
 * visible => show/hide the sidebar
 */
const SideBar = ({items, styles, visible, backdropHandler}) => {
  
  const createSlug = (item) => {
    item = item.toLowerCase();
    item = item.replace(' ', '-');
    return item;
  };

  let sideBarClass = 'sidebar';
  if (visible) {
    sideBarClass = 'sidebar visible';
  }
  const {sideBarColor, sideBarFontFamily, sideBarFontSize} = styles;
  return (
    <div className={sideBarClass} >
      <ul>
        {items.map((item, index) => (
          <li key = {index} onClick={backdropHandler}>
            <Link className={`${createSlug(item)}-link`} to={`/${createSlug(item)}`} style={{
              color:sideBarColor, 
              fontSize:sideBarFontSize, 
              fontFamily:sideBarFontFamily
            }}>
              {item}
            </Link> 
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;

import React from 'react';
import './aboutPage.styles.scss';
import About from '../../components/About/about.component';
import CardLayout from "../../components/CardLayout/cardlayout.component";
import Mem1 from "../../images/corePics/mahendra.JPG";
import Mem2 from "../../images/corePics/manita.JPG";
import Mem3 from "../../images/corePics/prarthana.jpg";
import Mem4 from "../../images/corePics/bhuwan.jpg";
import Mem5 from "../../images/corePics/aman.jpg";
import Mem6 from "../../images/corePics/anish.webp";

const imageStyles = {
  width: '100%',
  display: 'inline-block',
  height: '100%',
  objectFit: 'cover',
};

const aboutData = [
  {
    image: Mem1,
    text: `Mahendra Pandey\n5th year, NMC\nCore Member`,
    alt: 'Team',
    imageStyles: imageStyles
  },
  {
    image: Mem2,
    text: `Manita Khadka\n4th year, NAIHS\nCore Member`,
    alt: 'Team',
    imageStyles: imageStyles
  },
  {
    image: Mem3,
    text: `Prarthana Subedi\n4th year, NAIHS\nCore Member`,
    alt: 'Team',
    imageStyles: imageStyles
  },
  {
    image: Mem4,
    text: `Bhuwan Giri\n4th year, IOM\nCore Member`,
    alt: 'Team',
    imageStyles: imageStyles
  },
  {
    image: Mem5,
    text: `Aman Pandey\n4th year, NYUAD\nCore Member`,
    alt: 'Team',
    imageStyles: imageStyles
  },
  {
    image: Mem6,
    text: `Anish Chand\n4th year, UNO\nCore Member`,
    alt: 'Team',
    imageStyles: imageStyles
  },
];
const AboutPage = () => {
  return (
    <div className='about-page'>
      <div className='title'>ABOUT US</div>
      <About />
      <div className='spacer' />
      <div className='team'>
        Our Team
      </div>
      <CardLayout data={aboutData} />
    </div>
  );
};

export default AboutPage;

export const projectList = [
  {
    id: "1",
    title: "MedEd Series",
    description: `<p>Med-Ed Series, one of the projects run by Medlife stories, is a peer learning platform where medical students around the country come together for a teaching-learning engagement. Cognitive - Congruence hypothesis states that , we learn better with people of our cognitive congruence. This platform creates a space for congruent learning where students can learn without fear of shame and judgement.</p> 
         <p>The project runs as a 1 hour session every Saturday. The students who apply to become a Medlife Tutor presents the session on different topics of Medicine. All the students who register for the session or/and are a part of the Medlife Community can attend the lecture and get actively involved in the teaching-learning process. The program has been received with great interest and appreciation from around the country.  It has been able to be a safe space for students where they learn and work in different spheres without shame and a fear of judgment.</p>
         <p>One of the Medlife tutor shares her experience like this:</p>
         <p>“Being a Medlife Tutor comes with many perks. The tutors get to hone their skills of presentation, work on their communication skill, confidence and learn the essentials of hosting their own webinar.”</p>`,
    buttonText: ["BE A TUTOR", "ATTEND SESSION"],
    buttonLinks: ["/contact", "/contact"],
  },
  {
    id: "2",
    title: "Medical Ethics Workshops",
    description: `<p>The committee will be responsible for planning, preparation, and execution of workshops for topics related to Medical Ethics.</p>`,
    buttonText: ["REGISTER"],
    buttonLinks: ["/contact"],
  },
  {
    id: "3",
    title: "Medlife Blog",
    description: `<p>You can join as editors for the Medlife blog. This team will be responsible for handling, editing, and making articles ready for publishing.</p>`,
    buttonText: ["JOIN"],
    buttonLinks: ["/contact"],
  },
  {
    id: "4",
    title: "MCQ and Module Development",
    description: `<p>This committee will work in close collabaration with tutors to prepare modules, and also help in mobilizing the resources to its best extent for preparing the guiding framework for MCQ.</p>`,
    buttonText: ["JOIN"],
    buttonLinks: ["/contact"],
  },
];

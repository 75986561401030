import React from 'react';
import './imageandtext.styles.scss';

/**
 * image => image src
 * alt => img alt
 * imageLeft => image on Left vs text on Left
 * @param {*} param0
 */
const ImageAndText = ({
  image,
  alt,
  header,
  text,
  headerStyles,
  imageStyles,
  textStyles,
  imageRight,
  imageHide
}) => {
  const { display, width, height, objectFit } = imageStyles;
  const { fontSize, fontFamily } = textStyles;
  const { headerSize, headerFontFamily } = headerStyles;
  let containerClass = 'image-and-text-container';
  if (imageRight) {
    containerClass += ' reverse';
  }
  if(imageHide) {
      containerClass += ' hide';
  }
  let imageComponent = <span/>
  if(image !== '') {
      imageComponent = <div className='image-container'>
    <img
        src={image}
        alt={alt}
        style={{
            display: display,
            width: width,
            height: height,
            objectFit: objectFit,
        }}
    />
  </div>
  }
  let headerComponent = <span/>
  if(header !== ""){
    headerComponent = <div className='header' style={{ fontSize: headerSize }}>
        {header}
    </div>
  }
  return (
    <div className='container'>
        {headerComponent}
      <div className={containerClass}>
          {imageComponent}
        <div className='text-container' style={{ fontSize: fontSize }}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default ImageAndText;

import React, { useRef } from 'react';
import './form.styles.scss';
import { submitEvent } from './form-controller';

const Form = ({ formOptions }) => {
  const { header, url } = formOptions;
  const nameRef = useRef(null);
  const collegeRef = useRef(null);
  const yearRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const socialRef = useRef(null);
  const howRef = useRef(null);

  return (
    <div className='form-container'>
      <h2>{header}</h2>
      <form className='form' onSubmit={(e) => {
          e.preventDefault();
          submitEvent(
              nameRef.current.value,
              collegeRef.current.value,
              yearRef.current.value,
              emailRef.current.value,
              addressRef.current.value,
              socialRef.current.value,
              howRef.current.value
          )
        }
      }>
        <label htmlFor='Name'>Name:</label>
        <input type='text' name='Name' ref={nameRef} required />
        <label htmlFor='college'>Medical College:</label>
        <input type='text' name='college' ref={collegeRef} required />
        <label htmlFor='year'>Medical College Year:</label>
        <input
          type='text'
          name='year'
          ref={yearRef}
          placeholder='1st year'
          required
        />
        <label htmlFor='email'>Email:</label>
        <input type='text' name='email' ref={emailRef} required />
        <label htmlFor='address'>Address:</label>
        <input type='text' name='address' ref={addressRef} required />
        <label htmlFor='social'>Social Media:</label>
        <input
          type='url'
          name='social'
          ref={socialRef}
          placeholder='https://www.example.com'
          pattern='https://.*'
          required
        />
        <label htmlFor='how'>How did you hear about us?:</label>
        <textarea className='comments' type='text' name='how' ref={howRef} />
        <br />
        <br />
        <input className='button' type='submit' value='Submit' />
      </form>
    </div>
  );
};

export default Form;
